<template>
  <div>
    <RevInfoBlock
      :content="i18n(translations.messageContent)"
      :icon="IconInfo"
      :title="i18n(translations.messageTitle)"
      variant="info"
    />
    <div
      v-for="{ item, hasShippingOptions, shippingOptions } in displayedItems"
      :key="item.listingId"
    >
      <div class="mt-32 flex flex-col gap-16 md:flex-row md:items-center">
        <CheckoutSummaryLine
          class="w-full md:mt-1 md:self-start"
          price-variant=""
          :quantity="item.quantity"
          :subtitle="item.gradeName"
          :thumbnail="item.image"
          :title="item.title"
        />

        <div class="flex w-full flex-row md:justify-end">
          <RevButtonTiny
            class="w-full md:w-auto"
            :loading="loading"
            variant="secondary"
            @click.prevent="handleRemoveButtonClick(item)"
          >
            {{ i18n(translations.remove) }}
          </RevButtonTiny>
        </div>
      </div>

      <div class="mt-8">
        <RevInputSelect
          v-if="hasShippingOptions"
          :id="`shipping-${item.listingId}`"
          v-model="selectedShippingIds[item.listingId]"
          class="grow"
          :disabled="loading"
          :label="i18n(translations.label)"
          :options="shippingOptions"
        />
        <RevInputSelect
          v-if="!hasShippingOptions"
          :id="`shipping-${item.listingId}`"
          class="grow"
          disabled
          :error="i18n(translations.unavailable)"
          label=""
          :options="shippingOptions"
          value=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { mapValues } from '@backmarket/utils/object/mapValues'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevInputSelect } from '@ds/components/InputSelect'
import { IconInfo } from '@ds/icons/IconInfo'

import CheckoutSummaryLine from '~/scopes/checkout/components/CheckoutSummaryLine/CheckoutSummaryLine.vue'
import { useFormatHours } from '~/scopes/checkout/composables/useFormatHours'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'

import translations from './UnavailableShippings.translations'

const props = defineProps({
  addressCountry: { type: String, required: true },
  loading: { type: Boolean, default: false },
})

const emit = defineEmits(['remove', 'update'])

const i18n = useI18n()

const cartStore = useCartStore()

const { getFormatHoursInDay } = useFormatHours()

const selectedShippingIds = ref({})

const selectedShippings = computed(() => {
  return mapValues(
    selectedShippingIds.value,
    (shippingId) => cartStore.shippingsById[shippingId] || null,
  )
})

const countryShippings = computed(() => {
  return cartStore.shippingsByCountry[props.addressCountry] || {}
})

const unshippableItems = computed(() => {
  return cartStore.getUnshippableItems(props.addressCountry)
})

const getShippingOptions = (item) => {
  return (countryShippings.value[item.listingId] || []).map((shipping) => ({
    label: [
      getFormatHoursInDay(shipping.shippingDelay),
      shipping.shipperDisplay,
      shipping.shippingPriceWithCurrency,
    ].join(' - '),
    value: String(shipping.shippingId),
  }))
}

const displayedItems = computed(() => {
  return mapValues(unshippableItems.value, (item) => {
    const shippingOptions = getShippingOptions(item)

    return {
      item,
      shippingOptions,
      hasShippingOptions: !isEmpty(shippingOptions),
    }
  })
})

const handleRemoveButtonClick = async (item) => {
  emit('remove', item)
}

watch(
  unshippableItems,
  () => {
    selectedShippingIds.value = mapValues(unshippableItems.value, (item) => {
      return String(
        cartStore.shippingsById[item.shippingSelected.shippingFeePk].countries[
          props.addressCountry
        ]
          ? item.shippingSelected.shippingFeePk
          : countryShippings.value[item.listingId][0].shippingId || '',
      )
    })
  },
  {
    immediate: true,
  },
)

watch(
  selectedShippingIds,
  () => {
    emit('update', selectedShippings.value)
  },
  {
    immediate: true,
    deep: true,
  },
)
</script>
