<template>
  <div class="flex">
    <div
      v-if="shouldDisplayThumbnail"
      class="body-2-bold visible relative mr-[2.1rem] flex h-[4rem] w-[4rem] justify-center"
    >
      <span
        v-if="shouldDisplayQuantity"
        class="absolute right-0 top-0 z-10 inline-flex h-[1.8rem] w-[1.8rem] -translate-y-2/4 translate-x-2/4 items-center justify-center rounded-[50%]"
      >
        {{ quantity }}
      </span>
      <RevIllustration
        v-if="thumbnail"
        :alt="title"
        class="w-[4rem]"
        :height="40"
        :src="thumbnail"
        :width="40"
      />
    </div>

    <div v-if="shouldDisplayQuantityOnly" class="mr-3 flex justify-center">
      <span
        class="inline-flex h-[1.8rem] w-[1.8rem] translate-y-[-0.2rem] items-center justify-center rounded-[50%]"
      >
        {{ quantity }}
      </span>
    </div>

    <div class="grow">
      <div :class="titleVariant">
        {{ title }}
      </div>

      <div v-if="subtitle" class="body-2 mt-1 truncate">
        {{ subtitle }}
      </div>
    </div>

    <div v-if="priceVariant" :class="priceClass" data-qa="price">
      {{ price }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RevIllustration } from '@ds/components/Illustration'

const props = withDefaults(
  defineProps<{
    quantity?: number
    thumbnail?: string
    title?: string
    subtitle?: string
    price?: string
    titleVariant?: string
    priceVariant?: string
  }>(),
  {
    quantity: 0,
    thumbnail: '',
    title: '',
    subtitle: '',
    price: '',
    titleVariant: 'body-2-bold',
    priceVariant: 'body-2-light',
  },
)

const shouldDisplayThumbnail = computed(() => {
  return props.quantity && props.thumbnail
})

const shouldDisplayQuantity = computed(() => {
  return props.quantity > 1
})

const shouldDisplayQuantityOnly = computed(() => {
  return props.quantity && props.quantity > 1 && !props.thumbnail
})

const priceClass = computed(() => {
  return `ml-3 text-right ${props.priceVariant}`
})
</script>
