export default {
  messageTitle: {
    id: 'funnel_unavailable_shippings_message_title',
    defaultMessage: "You've selected delivery to another country",
  },
  messageContent: {
    id: 'funnel_unavailable_shippings_message_content',
    defaultMessage:
      "Some items in your cart may not be available for delivery in the country you've specified... or additional charges may apply.",
  },
  label: {
    id: 'funnel_unavailable_shippings_label',
    defaultMessage: 'Select another shipping method',
  },
  unavailable: {
    id: 'funnel_unavailable_shippings_unavailable',
    defaultMessage: 'No delivery method available for this product.',
  },
  remove: {
    id: 'funnel_unavailable_shippings_remove',
    defaultMessage: 'Remove',
  },
}
